import React from "react";
// libs
import Image from "next/image";
// components
import Link from "src/components/ui/Link";
import Button, { ButtonContainer } from "src/components/ui/button";
// hooks
import { useAppSelector } from "src/hooks";
// redux
import { dynamicStateFieldSelector } from "src/redux/slices";
// const
import { HOW_IT_WORKS_BLOCK } from "src/constants/dynamic";
// theme
import { AvatarsContainer, IllustrationWrapper, TopContainer } from "./Action.styles";
// types
import { IDynamicHowItWorks } from "src/types/dynamic/clinics/howItWorks";
import { EVariants } from "src/constants/variants";
import { useRouter } from "next/router";
import { amplitudeEvent, formatEventName } from "src/libs/amplitude";
import { Body, LargeTitle, TextContainer } from "src/theme/typography";
import { SectionCard } from "src/components/ui/common";
import { colors } from "src/theme";

const Action = () => {
  const data = useAppSelector(dynamicStateFieldSelector<IDynamicHowItWorks>(HOW_IT_WORKS_BLOCK));
  const { pathname } = useRouter();

  const splitTitle = data?.title.split("most successful");
  const formattedTitle = (
    <>
      {splitTitle[0]}
      <span style={{ color: colors.main }}>most successful</span>
      {splitTitle[1]}
    </>
  );

  const handleOnClick = (title: string) => {
    amplitudeEvent(formatEventName(pathname, "bottom_cta", title));
  };

  return (
    <SectionCard style={{ backgroundColor: colors.orange }}>
      <IllustrationWrapper>
        <Image src="/illustrations/budgie.png" height={200} width={200} />
      </IllustrationWrapper>
      <TopContainer>
        <AvatarsContainer>
          <Image src="/images/customers/emma-pennard.png" height={96} width={96} />
          <Image src="/images/customers/ellie-castle-vets.png" height={96} width={96} />
          <Image src="/images/customers/sian-the-vet.png" height={96} width={96} />
        </AvatarsContainer>
        <Body
          textColor={colors.white}
          style={{ gridArea: "text", justifySelf: "flex-start", alignSelf: "center" }}
        >
          +2,000 more
        </Body>
      </TopContainer>
      <TextContainer gap="24px">
        <LargeTitle textColor={colors.white}>{formattedTitle}</LargeTitle>
        {data?.description && (
          <Body textColor={colors.white} style={{ maxWidth: 780 }}>
            {data?.description}
          </Body>
        )}
      </TextContainer>
      <ButtonContainer>
        {data?.buttons.map(({ title, type, new_tab, url, id }) => {
          if (!url) return null;

          const localLink = (
            <Link href={url} key={id}>
              <Button
                variant={type.toUpperCase() as EVariants}
                onClick={() => handleOnClick(title)}
              >
                {title}
              </Button>
            </Link>
          );

          const outerLink = (
            <a href={url} target="_blank" key={id} rel="noreferrer">
              <Button
                variant={type.toUpperCase() as EVariants}
                onClick={() => handleOnClick(title)}
              >
                {title}
              </Button>
            </a>
          );

          return new_tab ? outerLink : localLink;
        })}
      </ButtonContainer>
    </SectionCard>
  );
};

export default Action;
