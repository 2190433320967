import device from "src/constants/media";
import styled from "styled-components";

export const TopContainer = styled.div`
  display: grid;
  grid-template-areas: "avatars text";
  grid-template-columns: repeat(2, auto);
  gap: 14px;

  @media ${device.mobileL} {
    width: 100%;
    gap: 24px;
    grid-template-areas: ". avatars text";
    grid-template-columns: 1fr auto 1fr;
  }
`;

export const IllustrationWrapper = styled.div`
  position: absolute;
  bottom: 48px;
  left: -168px;
`;

export const AvatarsContainer = styled.div`
  display: grid;
  grid-area: avatars;
  grid-template-columns: repeat(3, 64px);
  place-items: center;
  img {
    border-radius: 200px;
  }
  div {
    min-height: 80px;
    min-width: 80px;
  }

  @media ${device.tablet} {
    grid-template-columns: repeat(3, 72px);
    div {
      min-height: 96px;
      min-width: 96px;
    }
  }
`;
